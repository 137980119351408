import { Dispatch } from 'react';

import { API_STATUSES } from '../../constants';
import { reportApiError } from '../../errors';
import { adrollApi } from '../../services/adroll';
import { ActionMap } from '../../typings';
import {
    ISetFetchErrorPayload,
    ISetFetchInProgressPayload,
    ISetFetchSuccessPayload,
    ISetInputValuePayload,
    setFetchError,
    setFetchInProgress,
    setFetchSuccess,
    setInputValue,
} from '../common';

export enum ACTION_TYPES {
    TFA_SET_CODE = 'TFA_SET_CODE',
    TFA_FETCH_METHODS_IN_PROGRESS = 'TFA_FETCH_METHOD_IN_PROGRESS',
    TFA_FETCH_METHODS_SUCCESS = 'TFA_FETCH_METHOD_SUCCESS',
    TFA_FETCH_METHODS_ERROR = 'TFA_FETCH_METHOD_ERROR',
    TFA_SEND_SMS_IN_PROGRESS = 'TFA_SEND_SMS_IN_PROGRESS',
    TFA_SEND_SMS_SUCCESS = 'TFA_SEND_SMS_SUCCESS',
    TFA_SEND_SMS_ERROR = 'TFA_SEND_SMS_ERROR',
    TFA_SUBMIT_IN_PROGRESS = 'TFA_SUBMIT_IN_PROGRESS',
    TFA_SUBMIT_SUCCESS = 'TFA_SUBMIT_SUCCESS',
    TFA_SUBMIT_ERROR = 'TFA_SUBMIT_ERROR',
}

export interface ISetFetchMethodsSuccessPayload {
    status: API_STATUSES;
    methods: string[];
    redirect: string;
    to: string;
}

export interface ISetFetchMethodsErrorPayload {
    status: API_STATUSES;
}

export interface ISetSubmitSuccessPayload {
    status: API_STATUSES;
    redirect: string;
    to: string;
}

type TFAPayloads = {
    [ACTION_TYPES.TFA_SET_CODE]: ISetInputValuePayload;
    [ACTION_TYPES.TFA_FETCH_METHODS_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.TFA_FETCH_METHODS_SUCCESS]: ISetFetchMethodsSuccessPayload;
    [ACTION_TYPES.TFA_FETCH_METHODS_ERROR]: ISetFetchMethodsErrorPayload;
    [ACTION_TYPES.TFA_SEND_SMS_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.TFA_SEND_SMS_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.TFA_SEND_SMS_ERROR]: ISetFetchErrorPayload;
    [ACTION_TYPES.TFA_SUBMIT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.TFA_SUBMIT_SUCCESS]: ISetSubmitSuccessPayload;
    [ACTION_TYPES.TFA_SUBMIT_ERROR]: ISetFetchErrorPayload;
};

export type TFAActions = ActionMap<TFAPayloads>[keyof ActionMap<TFAPayloads>];

export const setCodeFactory =
    (dispatchFn: Dispatch<TFAActions>, alreadySubmittedOnce: boolean) =>
    (event: any) => {
        const code = event.target.value;
        dispatchFn(
            setInputValue(ACTION_TYPES.TFA_SET_CODE, code, () => [true, null])
        );
        if (code.length === 6 && !alreadySubmittedOnce) {
            submitFactory(dispatchFn, code)();
        }
    };

const setFetchMethodsSuccess = (response: any): TFAActions => {
    return {
        type: ACTION_TYPES.TFA_FETCH_METHODS_SUCCESS,
        payload: {
            status: API_STATUSES.SUCCESS,
            methods: response.tfa_methods,
            redirect: response.type,
            to: response.action,
        },
    };
};

const setFetchMethodsError = (): TFAActions => {
    return {
        type: ACTION_TYPES.TFA_FETCH_METHODS_ERROR,
        payload: {
            status: API_STATUSES.ERROR,
        },
    };
};

const setSubmitSuccess = (response: any): TFAActions => {
    return {
        type: ACTION_TYPES.TFA_SUBMIT_SUCCESS,
        payload: {
            status: API_STATUSES.SUCCESS,
            redirect: response.type,
            to: response.action,
        },
    };
};

export const fetchMethodsFactory =
    (dispatchFn: Dispatch<TFAActions>) => async () => {
        dispatchFn(
            setFetchInProgress(ACTION_TYPES.TFA_FETCH_METHODS_IN_PROGRESS)
        );
        try {
            const res = await adrollApi.get('entryhall/init_tfa_page');
            dispatchFn(setFetchMethodsSuccess(res));
        } catch (error) {
            dispatchFn(setFetchMethodsError());
        }
    };

export const sendSMSFactory =
    (dispatchFn: Dispatch<TFAActions>) => async () => {
        dispatchFn(setFetchInProgress(ACTION_TYPES.TFA_SEND_SMS_IN_PROGRESS));
        try {
            await adrollApi.post('entryhall/send_sms_tfa', {}, {});
            dispatchFn(setFetchSuccess(ACTION_TYPES.TFA_SEND_SMS_SUCCESS));
        } catch (error) {
            dispatchFn(setFetchError(ACTION_TYPES.TFA_SEND_SMS_ERROR, error));
        }
    };

export const submitFactory =
    (dispatchFn: Dispatch<TFAActions>, code: string) => async () => {
        dispatchFn(setFetchInProgress(ACTION_TYPES.TFA_SUBMIT_IN_PROGRESS));
        try {
            const res = await adrollApi.post(
                'entryhall/verify_tfa',
                {},
                {
                    token: code,
                }
            );
            dispatchFn(setSubmitSuccess(res));
        } catch (error) {
            reportApiError(error);
            dispatchFn(setFetchError(ACTION_TYPES.TFA_SUBMIT_ERROR, error));
        }
    };
