import { useEffect, useState } from 'react';

import { API_STATUSES } from '../constants';

interface IProps {
    status: string;
}

const TRANSIENT_STATUSES: string[] = [API_STATUSES.ERROR, API_STATUSES.SUCCESS];
const TRANSIENT_ELEM_DURATION = 10 * 1000; // 10secs.

const InlineFeedback = ({ status }: IProps) => {
    const [visible, setVisible] = useState(true);
    useEffect(() => {
        setVisible(true);

        if (TRANSIENT_STATUSES.includes(status) === false) {
            return;
        }

        const timeout = setTimeout(
            () => setVisible(false),
            TRANSIENT_ELEM_DURATION
        );
        return () => clearTimeout(timeout);
    }, [status]);

    if (!visible) {
        return <></>;
    }

    switch (status) {
        case API_STATUSES.IN_PROGRESS:
            return <div className='ar-spinner inline-feedback' />;
        case API_STATUSES.SUCCESS:
            return <i className='fas ar-icon-success inline-feedback' />;
        case API_STATUSES.ERROR:
            return <i className='fas ar-icon-danger inline-feedback' />;
        default:
            return <></>;
    }
};

export default InlineFeedback;
