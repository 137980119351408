import { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { InputGroup } from '@nextroll/ar-style-base';

import {
    fetchMethodsFactory,
    sendSMSFactory,
    setCodeFactory,
    submitFactory,
} from '../../actions/sign-in/TFAActions';
import FormSpinner from '../../components/FormSpinner';
import InlineFeedback from '../../components/InlineFeedback';
import { ApiError } from '../../components/errors';
import { API_STATUSES } from '../../constants';
import ThemeWrapperContext from '../../contexts/ThemeWrapperContext';
import TFAContext from '../../contexts/sign-in/TFAContext';
import { adrollApi } from '../../services/adroll';

export const SMS_METHOD = 'tfa_sms_enabled';
export const GAPP_METHOD = 'tfa_google_authenticator_enabled';

const TFAForm = () => {
    const { supportEmail } = useContext(ThemeWrapperContext);
    const { state, dispatch } = useContext(TFAContext);
    const [redirectSpinner, setRedirectSpinner] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const navigateRedirect = (redirect: string, to: string) => {
        if (redirect === 'internal_redirect') {
            navigate(`../${to}`);
        } else if (redirect === 'external_redirect') {
            setRedirectSpinner(true);
            window.location.href = to;
        }
    };
    const code = state.get('code');
    const alreadySubmittedOnce = state.get('alreadySubmittedOnce');
    const {
        status: submitStatus,
        error: submitError,
        showError: showSubmitError,
        redirect: submitRedirect,
        to: submitTo,
    } = state.get('submit');
    const {
        status: initStatus,
        methods,
        redirect: initRedirect,
        to: initTo,
    } = state.get('init_status');
    const {
        status: smsStatus,
        error: smsError,
        showError: showSmsError,
    } = state.get('sms');

    const fetchInit = fetchMethodsFactory(dispatch);
    const setCode = setCodeFactory(dispatch, alreadySubmittedOnce);
    const submit = submitFactory(dispatch, code.value);
    const sendSMS = sendSMSFactory(dispatch);

    const tfaSMS = methods && methods.includes(SMS_METHOD);
    const tfaGApp = methods && methods.includes(GAPP_METHOD);

    const showForm = initStatus === API_STATUSES.SUCCESS && !initRedirect;
    const fetchMethodsError = initStatus === API_STATUSES.ERROR;

    const signOut = async () => {
        await adrollApi.post('/account/signout', {}, {});
        navigateRedirect('internal_redirect', 'signin');
    };

    useEffect(() => {
        if (initStatus === API_STATUSES.IDLE) {
            fetchInit();
        } else if (initStatus === API_STATUSES.SUCCESS) {
            if (initRedirect) {
                navigateRedirect(initRedirect, initTo);
            } else if (tfaSMS && !tfaGApp) {
                if (smsStatus === API_STATUSES.IDLE) {
                    sendSMS();
                }
            }
        }
    }, [state.get('init_status')]);

    useEffect(() => {
        if (tfaSMS && smsStatus === API_STATUSES.SUCCESS) {
            inputRef.current?.focus();
        }
    }, [smsStatus]);

    useEffect(() => {
        if (submitStatus === API_STATUSES.SUCCESS) {
            navigateRedirect(submitRedirect, submitTo);
        }
    }, [state.get('submit')]);

    const onSubmit =
        submitStatus !== API_STATUSES.IN_PROGRESS ? submit : undefined;
    const onFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit && onSubmit();
        return false;
    };

    if (showForm && !redirectSpinner) {
        return (
            <div className='entryhall-card-block entryhall-form-tfa'>
                <form id='tfa_form' onSubmit={onFormSubmit}>
                    <input type='submit' hidden />
                    <div className='entryhall-card-header'>
                        <p className='entryhall-card-header-title'>
                            {i18n.gettext('Enter Two - Step Verification Code')}
                        </p>
                        {!showSubmitError && (
                            <p>
                                {i18n.gettext(
                                    'Your Account has been set up for Two - Step Verification. Enter your verification code.'
                                )}
                            </p>
                        )}
                        {showSubmitError && <ApiError error={submitError} />}
                        {showSmsError && <ApiError error={smsError} />}
                    </div>
                    <div className='entryhall-card-body'>
                        <InputGroup
                            i18n={i18n}
                            classNames='entryhall-tfa-code-input'
                            inputProps={{
                                autoComplete: 'one-time-code',
                                autoFocus: true,
                                id: 'token',
                                placeholder: 123456,
                                value: code.value,
                                onChange: setCode,
                                ref: inputRef,
                            }}
                            validationState={
                                showSubmitError
                                    ? InputGroup.VALIDATION_ERROR
                                    : ''
                            }
                        />
                    </div>
                    <div className='entryhall-card-footer'>
                        <section className='entryhall-tfa-actions'>
                            <button
                                type='button'
                                className='btn btn-default'
                                onClick={signOut}
                            >
                                {i18n.gettext('Sign Out')}
                            </button>
                            <button
                                type='button'
                                id='submit-tfa'
                                className='btn btn-primary'
                                onClick={onSubmit}
                            >
                                {i18n.gettext('Verify & Sign in')}
                                <InlineFeedback status={submitStatus} />
                            </button>
                        </section>
                        {tfaSMS && (
                            <section className='entryhall-tfa-sms-actions'>
                                {!tfaGApp && (
                                    <span>
                                        {i18n.gettext(
                                            'Verification code not received?'
                                        )}
                                    </span>
                                )}
                                <button
                                    type='button'
                                    className='btn btn-link send-sms-action inline-anchor'
                                    onClick={
                                        smsStatus !== API_STATUSES.IN_PROGRESS
                                            ? sendSMS
                                            : undefined
                                    }
                                >
                                    {tfaGApp &&
                                        i18n.gettext('Send code via SMS')}
                                    {!tfaGApp && i18n.gettext('Resend SMS')}
                                    <InlineFeedback status={smsStatus} />
                                </button>
                            </section>
                        )}
                    </div>
                </form>
            </div>
        );
    } else if (fetchMethodsError) {
        return (
            <div className='entryhall-card-block entryhall-form-tfa entryhall-tfa-init-error'>
                <div className='entryhall-card-header'>
                    <p className='entryhall-error-text'>
                        {i18n.gettext(
                            'Something went wrong while loading the page. Please try again. If you continue having issues please reach out to <LINK>{{supportEmailAddress}}</LINK>',
                            {
                                LINK: (
                                    <a
                                        href={`mailto:${supportEmail}`}
                                        target='_blank'
                                    />
                                ),
                                supportEmailAddress: supportEmail,
                            }
                        )}
                    </p>
                </div>
            </div>
        );
    } else {
        return <FormSpinner />;
    }
};

export default TFAForm;
