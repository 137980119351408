import { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { Alerts } from '@nextroll/ar-style-base';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

const tfaMethods = [
    {
        label: i18n.noop.gettext('Mobile app verification'),
        link: '../setup-mobile-app-tfa',
    },
    {
        label: i18n.noop.gettext('Text message verification'),
        link: '../setup-sms-tfa',
    },
];

const TFAMethodsPage = () => {
    const locationState = useLocation().state as
        | { success: boolean }
        | undefined;
    const setupSuccess = locationState?.success;
    const [successVisible, setSuccessVisible] = useState(true);
    const onDismiss = useCallback(
        () => setSuccessVisible(false),
        [successVisible]
    );

    return (
        <div className='entryhall-page-tfa-methods'>
            <Header />
            {setupSuccess && successVisible && (
                <Alerts
                    i18n={i18n}
                    contextualAlerts={[
                        {
                            title: i18n.gettext(
                                'The Verification method has been enabled.'
                            ),
                            timestamp: Date.now(),
                            type: 'success',
                            alertKey: '0',
                        },
                    ]}
                    onDismissContextualAlert={onDismiss}
                    isStackable={false}
                />
            )}
            <div className='entryhall-card-2'>
                <div className='entryhall-card-header'>
                    <p className='entryhall-card-header-title'>
                        {i18n.gettext('Enable Two-Factor Authentication')}
                    </p>
                    <p>
                        {i18n.gettext(
                            'Two-factor authentication (TFA) is an additional layer of protection to your account to help protect against unauthorized account access.'
                        )}
                    </p>
                    <p>
                        {i18n.gettext(
                            'Please choose and configure at least one verification method.'
                        )}
                    </p>
                </div>
                <div className='entryhall-card-body entryhall-tfa-methods-list'>
                    {tfaMethods.map(
                        ({ label, link }: { label: string; link: string }) => (
                            <Link key={label} to={link}>
                                <button className='ar-card-button'>
                                    <span>{i18n.gettext(label)}</span>
                                    <i className='fas fa-arrow-right' />
                                </button>
                            </Link>
                        )
                    )}
                </div>
                {setupSuccess && (
                    <div className='entryhall-card-footer'>
                        <a
                            className='btn btn-primary'
                            href='/account/centralredirect'
                            rel='noreferrer'
                        >
                            {i18n.gettext('Continue to Account')}
                        </a>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default TFAMethodsPage;
