import { ACTION_TYPES, TFAActions } from '../../actions/sign-in/TFAActions';
import { ImmutableTFAState } from '../../contexts/sign-in/TFAContext';

const TFAReducer = (
    state: ImmutableTFAState,
    action: TFAActions
): ImmutableTFAState => {
    switch (action.type) {
        case ACTION_TYPES.TFA_SET_CODE:
            return state
                .set('code', action.payload)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.TFA_FETCH_METHODS_IN_PROGRESS:
            return state
                .setIn(['init_status', 'status'], action.payload.status)
                .setIn(['init_status', 'methods'], null)
                .setIn(['init_status', 'redirect'], null)
                .setIn(['init_status', 'to'], null);
        case ACTION_TYPES.TFA_FETCH_METHODS_SUCCESS:
            return state
                .setIn(['init_status', 'status'], action.payload.status)
                .setIn(['init_status', 'methods'], action.payload.methods)
                .setIn(['init_status', 'redirect'], action.payload.redirect)
                .setIn(['init_status', 'to'], action.payload.to);
        case ACTION_TYPES.TFA_FETCH_METHODS_ERROR:
            return state
                .setIn(['init_status', 'status'], action.payload.status)
                .setIn(['init_status', 'methods'], null)
                .setIn(['init_status', 'redirect'], null)
                .setIn(['init_status', 'to'], null);
        case ACTION_TYPES.TFA_SEND_SMS_IN_PROGRESS:
            return state
                .setIn(['sms', 'status'], action.payload.status)
                .setIn(['sms', 'error'], null)
                .setIn(['sms', 'showError'], false);
        case ACTION_TYPES.TFA_SEND_SMS_SUCCESS:
            return state
                .setIn(['sms', 'status'], action.payload.status)
                .setIn(['sms', 'error'], null)
                .setIn(['sms', 'showError'], false);
        case ACTION_TYPES.TFA_SEND_SMS_ERROR:
            return state
                .setIn(['sms', 'status'], action.payload.status)
                .setIn(['sms', 'error'], action.payload.error)
                .setIn(['sms', 'showError'], true);
        case ACTION_TYPES.TFA_SUBMIT_IN_PROGRESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'redirect'], null)
                .setIn(['submit', 'to'], null)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false)
                .set('alreadySubmittedOnce', true);
        case ACTION_TYPES.TFA_SUBMIT_ERROR:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'redirect'], null)
                .setIn(['submit', 'to'], null)
                .setIn(['submit', 'error'], action.payload.error)
                .setIn(['submit', 'showError'], true);
        case ACTION_TYPES.TFA_SUBMIT_SUCCESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'redirect'], action.payload.redirect)
                .setIn(['submit', 'to'], action.payload.to)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);
    }
};

export default TFAReducer;
